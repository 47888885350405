import { request } from "./network";
import da from "element-ui/src/locale/lang/da";

// 获取试列表
export function lists(params) {
    return request({
        method: "get",
        url: "/exam/listAll",
        params,
    });
}

//点击按钮传送不同状态
export function getStatus(subjectUuid,index) {
    return request({
        method: "get",
        url: `/exam/open?subjectUuid=${subjectUuid} && index=${index}`
    });
}

// 发送考试名称筛选老师对应的科目
// export function getAllteacherSubs(params) {
//     return request({
//         method: "get",
//         // url:"/teacher/exam/subject",
//         url: `/teacher/exam/subject?phone=${params.phone}&examId=${params.examId}&status=${params.status}&name=${params.name}`
//     });
// }
export function getAllteacherSubs(params) {
    return request({
        method: "get",
        // url:"/teacher/exam/subject",
        url: `/teacher/exam/subjectNew?phone=${params.phone}&examId=${params.examId}&status=${params.status}&name=${params.name}`
    });
}

// 根据examId显示当前考试所有科目的接口
export function getAllSubjectFormExamIda(params) {
    return request({
        method: "post",
        url: "/subject/getAllForExamId",
        params,
    });
}

// 根据examId获取考试信息
export function getExamInfoFormExamId(examId) {
    return request({
        method: "get",
        url: "exam/selectByUuid?uUid="+examId
    });
}

//获取教师监考管理的所有考试
export function getTeacherExams(userInfo) {
     return request({ 
         method: "get",
         url: "/teacher/exam?phone="+userInfo,
     })
}