<template>
  <el-container>
    <el-header height="auto">
      <Header :userInfo="userInfo"></Header>
    </el-header>
    <el-main class="main-box">
      <div class="box-header">
        <span
          >考试名称：
          <el-select v-model="examName" @change="getExamUuid">
            <el-option
              v-for="item in nameList"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </span>
      </div>
      <!-- card面板 -->
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <!-- 头部标题与form表单 -->
          <el-row :gutter="20">
            <!-- span属性指的是占据多少列 -->
            <el-col :span="6">
              <span>考试列表</span>
            </el-col>
            <!-- 选择按钮 -->
            <el-tooltip
              style="margin-left: 200px"
              :content="'Switch value: ' + value"
              placement="top"
            >
            <!-- 1开，2关 -->
              <el-switch
                v-model="value"
                active-color="#563279"
                inactive-color="#ccc"
                active-value="1"
                inactive-value="2"
                @change="changeStatusBtn"
              >
              </el-switch>
            </el-tooltip>
            <!-- 搜索框 -->
            <el-input
              v-model="examlistInfo.name"
              placeholder="请输入场次名称"
              prefix-icon="el-icon-search"
              class="myinput"
              clearable
              @input="sendExam"
            ></el-input>
            <!--选择框-->
            <el-select
              clearable
              v-model="examlistInfo.status"
              placeholder="考试状态"
              @change="sendExam"
            >
              <el-option label="考试状态" value=""></el-option>
              <el-option label="待开考" value="0"></el-option>
              <el-option label="考试中" value="1"></el-option>
              <el-option label="已结束" value="2"></el-option>
            </el-select>
          </el-row>
        </div>
        <div class="text item">
          <el-table
            stripe
            height="600"
            :header-cell-style="{
              backgroundColor: '#563279',
              color: '#FFFFFF',
            }"
            :data="examList"
          >
            <el-table-column
              prop="subjectName"
              label="场次名称"
              align="center"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="beginTime"
              label="开考时间"
              align="center"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="endTime"
              label="结束时间"
              align="center"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="考试状态"
              align="center"
              min-width="180"
            >
              <template slot-scope="scope">
                <!-- 0：待开考，1考试中，2已结束-->
                <span v-if="scope.row.status === 0" style="color: #e86b17"
                  >待开考</span
                >
                <span v-else-if="scope.row.status === 1" style="color: #442068"
                  >考试中</span
                >
                <span v-else style="color: #4d4d4d">已结束</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" min-width="230">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="gotoStudentList(scope.row)"
                  style="background-color: #563279"
                  >查看信息
                </el-button>
                <el-button
                  size="mini"
                  @click="gotoVideoList(scope.row)"
                  style="background-color: #8b2d87"
                  >在线监考
                </el-button>
              </template>
            </el-table-column>
          </el-table>

          <!-- 分页器 -->
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 40]"
            :current-page="currPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="selectInfoInExamList.length"
          >
          </el-pagination>
        </div>
      </el-card>
    </el-main>
    {{ this.$route.query.subjectUuid }}
  </el-container>
</template>

<script>
import Header from "c/index/Header";
import {
  getAllSubjectFormExamIda,
  getExamInfoFormExamId,
  getTeacherExams,
  getAllteacherSubs,
  getStatus,
} from "r/index/lists.js";

export default {
  name: "ExamLists",
  data() {
    return {
      userInfo: window.localStorage.getItem("index-userInfo"),
      searchTableInfo: "",
      examList: [],
      examlistInfo: {
        phone: "",
        pageNum: "",
        pageSize: "",
        name: "",
        examId: "",
        status: "",
        token: "",
      },
      subjectUuid:"",
      queryInfo: {
        examId: "",
        status: "",
      },
      value: "",
      examName: "",
      nameList: [],
      pageSize: 10,
      currPage: 1,
      getSearchInfo: [],
      examStatus: [
        {
          value: "0",
          label: "待开考",
        },
        {
          value: "1",
          label: "考试中",
        },
        {
          value: "2",
          label: "已结束",
        },
      ],
    };
  },
  created() {
        
    this.getTeacherExamLists();
    this.holdLast();
  },
  methods: {
    //发送不同状态1开 ，2关
   async changeStatusBtn() {
      let subjectUuid = this.subjectUuid
      let index = parseInt(this.value)
      // console.log(this.value,this.subjectUuid, "状态是啥" , (typeof this.value))
      const { data:res } = await getStatus(subjectUuid, index)
      // console.log(res,"返回状态")
    },
    // 选择考试
    getExamUuid(e) {
      // console.log(e);
      this.examlistInfo.examId = e.uuid;
      this.examName = e.name;
      this.sendExam();
    },
    async getTeacherExamLists() {
      const { data: res } = await getTeacherExams(this.userInfo);
      // console.log(res, "1111111111111");
      if (res.code !== 200) return this.$message.error(res.message + "1");
      this.nameList = res.data;
      // console.log(this.nameList, "记住上一个页面");
      this.examlistInfo.phone = this.userInfo;
      this.examlistInfo.examId = res.data[0].uuid;
      this.examName = res.data[0].name;
      this.sendExam();
    },
    //获取老师相对应的数据
    async sendExam() {
      const { data: res } = await getAllteacherSubs(this.examlistInfo);
      // console.log(res, "老师");
      if (res.code !== 200) return this.$message.error(res.message + "2");
      // console.log(res.data.examSubjects, "返回", this.examlistInfo.status);
      res.data.teacherExamDto.examSubjects.forEach((item) => {
        if (item.beginTime) {
          item.beginTime = this.dayjs(item.beginTime).format(
            "YYYY-MM-DD HH:mm:ss"
          );
        }
        if (item.endTime) {
          item.endTime = this.dayjs(item.endTime).format("YYYY-MM-DD HH:mm:ss");
        }
      });
      this.examList = res.data.teacherExamDto.examSubjects;
      this.subjectUuid = res.data.teacherExamDto.examSubjects[0].subjectUuid
      // console.log(res, "examList");
      //  //缓存teacherid
      //  window.localStorage.setItem('index-teacherId', res.data.map.teacherId)
      //  console.log(window.localStorage.getItem('index-teacherId'),'打印老师id')

      //  //缓存examid
      //   window.localStorage.setItem('index-examId', res.data.map.examUuid)
      //   console.log(window.localStorage.getItem('index-examId'),"返回值之后的缓存考试id")

      //   //缓存token
      //   window.localStorage.setItem('index-token', res.data.map.token)

      //  this.examlistInfo.examId = res.data.map.examUuid
      //  this.examlistInfo.token = res.data.map.token
      //  this.examlistInfo.teacherId = res.data.map.teacherId

      // this.getSearchInfo = res.data.teacherExamDto.examSubjects;
      // for( let i=0 ; i<this.getSearchInfo.length ; i++){
      //       //  this.getSearchInfo[i].teacherId = window.localStorage.getItem('index-teacherId')
      //       //  this.getSearchInfo[i].examId = window.localStorage.getItem('index-examId')
      //       this.getSearchInfo[i].teacherId = res.data.map.teacherId
      //        this.getSearchInfo[i].examId = res.data.map.examUuid
      // }
      this.getExamInfo();
      // console.log(this.getSearchInfo[0], "返回数据11111111111323333333");
    },

    async getExamInfo() {
      this.queryInfo.examId = this.examlistInfo.examId;
      // console.log(this.queryInfo.examId, "考试id");
      const { data: res } = await getExamInfoFormExamId(this.queryInfo.examId);
      if (res.code !== 200) return this.$message.error(res.message + "3");
      // console.log(res, "教师资料");
      this.examName = res.data.name;
    },
    // async getAllSubjectFormExamId() {
    //   console.log(this.queryInfo.status,"状态")
    //   this.queryInfo.examId = window.localStorage.getItem("index-examId");
    //   const { data: res } = await getAllSubjectFormExamIda(this.queryInfo);
    //   console.log(res,"返回搜索结果");
    //   if (res.code !== 200) return this.$message.error(res.message);

    //   res.data.forEach((item) => {
    //     if (item.beginTime) {
    //       item.beginTime = this.dayjs(item.beginTime).format(
    //         "YYYY-MM-DD HH:mm:ss"
    //       );
    //     }
    //     if (item.endTime) {
    //       item.endTime = this.dayjs(item.endTime).format("YYYY-MM-DD HH:mm:ss");
    //     }
    //   });

    //   this.examList = res.data;
    //   console.log(this.examList);
    //   this.getSearchInfo = res.data;
    //   console.log(this.getSearchInfo);
    // },
    // 页面跳转
    gotoStudentList(data) {
      // console.log(data)

      //点击跳转至上次浏览页面
      // this.$router.go(-1)
      //指定跳转地址
      // console.log(data, "22222222222222222222222222222222");
      //点击之后缓存examId用来保持上个页面保存的数据
      window.localStorage.setItem("holdId", data.examId);
      this.$router.push({
        name: "ExamStudentInfoList",
        query: {
          subjectUuid: data.subjectUuid,
          subjectName: data.subjectName,
          examUuid: data.examId,
        },
      });
      // this.$router.replace('/examination/ExamStudentInfoList')
    },
    gotoVideoList(data) {
      // console.log(data, "datatatattatatat");
      //点击之后缓存examId用来保持上个页面保存的数据
      window.localStorage.setItem("holdId", data.examId);
      //点击跳转至上次浏览页面
      // this.$router.go(-1)
      //指定跳转地址
      this.$router.push({
        name: "VideoList",
        query: {
          subjectId: data.subjectId,
          examUuid: data.examId,
          subjectUuid: data.subjectUuid,
          subjectName: data.subjectName,
          examName: this.examName,
        },
      });
      // console.log(this.data.subjectId, "subjectId");
      // this.$router.replace('/examination/VideoList')
    },

    //返回页面记住之前的数据
    holdLast() {
      if (window.localStorage.getItem("holdId")) {
        //进入下一个页面返回吧之后获取holdId保持之前的数据
        this.examlistInfo.examId = window.localStorage.getItem("holdId");
        window.localStorage.removeItem("holdId");
      }
    },

    // 控制每页显示几条数据
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      // this.getGroupPageLists();
    },
    // 控制显示第几页
    handleCurrentChange(newNum) {
      this.currPage = newNum;
      // this.getGroupPageLists();
    },
  },
  computed: {
    created() {
      this.getAllSubjectFormExamId();
      //this.sendExam()
    },
    // 筛选
    selectInfoInExamList() {
      const searchTableInfo = this.searchTableInfo;
      if (searchTableInfo) {
        // filter() 方法创建一个新的数组，新数组中的元素是通过检查指定数组中符合条件的所有元素。
        return this.getSearchInfo.filter((data) => {
          // console.log("success" + data);
          // some() 方法用于检测数组中的元素是否满足指定条件;
          return Object.keys(data).some((key) => {
            // indexOf() 返回某个指定的字符在某个字符串中首次出现的位置，如果没有找到就返回-1；
            // 该方法对大小写敏感！所以之前需要toLowerCase()方法将所有查询到内容变为小写。
            return (
              String(data[key]).toLowerCase().indexOf(searchTableInfo) > -1
            );
          });
        });
      }
      return this.getSearchInfo;
    },
  },
  components: {
    Header,
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/page";
@import "a/scss/indexList";
@import "a/scss/table";

// .el-container {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   flex-flow: column;
//   overflow: hidden;

//   .el-header {
//     width: 100%;
//     height: 64px;
//     background-color: #563279;
//     padding: 0;
//     box-shadow: 0px 3px 8px 0px rgba(1, 0, 1, 0.2);
//   }
//   .el-main {
//     padding: 20px 40px;
//     flex: 1;
//     display: flex;
//     flex-flow: column;
//     // overflow: hidden;
//     .box-header {
//       width: 100%;
//       height: 80px;
//       line-height: 80px;
//       background-color: #ffffff;
//       border-radius: 6px;
//       span {
//         font-size: 20px;
//         font-family: Source Han Sans CN;
//         font-weight: 400;
//         color: #333333;
//         margin-left: 29px;
//       }
//     }

//     .box-card {
//       flex: 1;
//       display: flex;
//       flex-flow: column;
//       border: none;
//       padding: 0;
//       margin-top: 17px;

//       flex: 1;
//       display: flex;
//       flex-flow: column;
//       border: none;
//       padding: 0;
//       margin-top: 24px;

//       &.is-always-shadow {
//         box-shadow: none;
//       }

//       ::v-deep .el-card__header {
//         height: 65px;
//         padding: 0 40px;
//         line-height: 65px;

//         span {
//           float: left;
//           font-size: 20px;
//           font-family: "Source Han Sans CN";
//           font-weight: 400;
//           color: #333333;
//         }
//         .el-input,.el-select {
//           float: right;
//           width: 200px;
//           height: 40px;
//           margin-left: 10px;

//           .el-input__inner {
//             color: #666666;
//           }
//           .el-input__prefix{
//             font-size: 16px;
//             width: 20px;
//             height: 20px;
//             text-align: center;
//             line-height: 20px;
//             position: absolute;
//             left: 15px;
//             top: 50%;
//             transform: translate(-50%, -40%);
//             color: #aaaaaa;
//           }
//           .el-input__suffix{
//             font-size: 16px;
//             width: 20px;
//             height: 20px;
//             text-align: center;
//             line-height: 20px;
//             position: absolute;
//             right: 5px;
//             top: 50%;
//             transform: translate(-50%, -40%);
//             color: #aaaaaa;
//           }
//         }
//       }

//       ::v-deep .el-card__body {
//         flex: 1;
//         display: flex;
//         flex-flow: column;
//         width: 100%;
//         padding: 24px;
//         overflow: auto;

//         .item{
//           flex:1;
//           height: 100%;
//           display: flex;
//           flex-flow: column;

//           .el-pagination{
//             height: 32px;
//           }
//           .el-table {
//             flex:1;
//             display: flex;
//             flex-flow: column;
//             overflow: auto;

//             .el-table__header-wrapper{
//               height: 50px;
//             }
//             .el-table__body-wrapper{
//               flex: 1;
//               overflow: auto;
//             }

//           }
//         }

//       }
//     }
//   }
// }
</style>
